import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import NotificationsSystem from 'reapop';
import theme from 'reapop-theme-wybo';

import svg4everybody from 'svg4everybody';
import Footer from './components/Footer';
// import GoogleTagManager from './components/GoogleTagManager';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import { routes } from './routes';
import RenderSwitch from './Switch';
import './sass/app.sass';

class App extends React.Component {
  componentDidMount() {
    require('viewport-units-buggyfill').init({
      force: false,
      refreshDebounceWait: 150,
    });

    svg4everybody();
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="page">
            <Header routes={routes.filter((route) => route.forNavBar)} />
            <div className="page__content">
              <NotificationsSystem theme={theme} />
              <RenderSwitch />
            </div>
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
