const ScrollTo = (element, duration) => {
  const scrollingElement = document.scrollingElement || document.documentElement;
  const start = scrollingElement.scrollTop;
  const startDate = +new Date();
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  // https://medium.com/@alexcambose/js-offsettop-property-is-not-great-and-here-is-why-b79842ef7582
  const getOffsetTop = (element) => {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  };

  let to = 0;

  if (typeof element === 'number') {
    to = element;
  } else if (typeof element === 'string') {
    const el = document.querySelector(element);
    if (el) {
      to = getOffsetTop(el); //.offsetTop;
    }
  }
  const change = to - start;

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;

    scrollingElement.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      scrollingElement.scrollTop = to;
    }
  };
  animateScroll();
};

export default ScrollTo;
