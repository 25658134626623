import { DateTime } from 'luxon';

const isWorkingHour = () => {
  // Call: Mon - Fri (9:00am  - 6:30pm, GMT +8)
  // Email: All other hours
  const luxonTime = DateTime.local().setZone('Asia/Singapore');
  const numTime = luxonTime.hour * 100 + luxonTime.minute;
  let weekday = luxonTime.weekday;
  const isWorkingDay = weekday !== 6 && weekday !== 7;
  const isWorkingHour = 900 < numTime && numTime < 1830;
  const isWorkingTime = isWorkingHour && isWorkingDay;
  return isWorkingTime;
};

export default isWorkingHour;
