import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import ScrollTo from 'services/ScrollTo';
import onClickOutside from 'react-onclickoutside';
import throttle from 'lodash/throttle';
import { OPEN_MENU, CLOSE_MENU } from 'store/ActionTypes';
import SvgIcon from 'components/SvgIcon';
import isWorkingHour from '../services/workingHours';
import { ReactComponent as LogoMark } from '../images/cabin-logo.svg';
import { ReactComponent as Logo } from '../images/cabin.svg';

class Header extends Component {
  static propTypes = {
    routes: PropTypes.array,
    menuOpened: PropTypes.bool,
    stateClass: PropTypes.string,
    openMenu: PropTypes.func,
    closeMenu: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.scrollWithThrottle = throttle(this.handleScroll, 200);

    this.state = {
      isScrolled: false,
      isWorkingTime: null,
    };
  }

  componentDidMount() {
    this.defineWorkingTime();
    window.addEventListener('scroll', this.scrollWithThrottle, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollWithThrottle, false);
  }

  handleScroll = (event) => {
    var wScroll = window.scrollY;

    if (wScroll > 10) {
      this.setState({
        isScrolled: true,
      });
    } else {
      if (this.state.isScrolled) {
        this.setState({
          isScrolled: false,
        });
      }
    }
  };

  toggleHamburger = () => {
    this.props.menuOpened ? this.props.closeMenu() : this.props.openMenu();
  };

  closeHamburger = () => {
    if (this.props.menuOpened) {
      this.props.closeMenu();
    }
  };

  handleClickOutside = () => {
    this.closeHamburger();
  };

  preloaderOnHover = (component) => {
    component.preload();
  };

  defineWorkingTime = () => {
    this.setState({ isWorkingTime: isWorkingHour() });
  };

  renderContacts = () => {
    if (this.state.isWorkingTime) {
      return (
        <Fragment>
          <a href="tel:+6531585495" className="header__phone header__phone--phone">
            <span>
              Have an enquiry?{' '}
              <span className="header__phone-tel">
                <span>Call us at:</span> +65 3158 5495
              </span>
            </span>
          </a>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <a href="mailto:hello@cabin.sg" className="header__phone header__phone--mail">
            <span>
              Have an enquiry? Email us at:<span className="header__phone-tel">hello@cabin.sg</span>
            </span>
          </a>
        </Fragment>
      );
    }
  };
  render() {
    const { routes, menuOpened } = this.props;
    const { location } = this.props;
    const hasForm = /\/pricing\/|\/services\/|^\/$/.test(location.pathname);

    return (
      <div className={this.props.stateClass + (this.state.isScrolled ? ' is-scrolled' : '')}>
        <header className="header">
          <div className="container">
            <div className="header__wrapper">
              <NavLink onClick={this.closeHamburger} to="/" className="header__logolink">
                <LogoMark height="25" width="25" preserveAspectRatio="none" className="header__logomark" />
                <Logo height="25" width="93" preserveAspectRatio="none" className="header__logo" />
              </NavLink>
              {this.renderContacts()}
              <ul className="header__menu">
                {routes.map((route, i) => (
                  <li key={i}>
                    <NavLink
                      onMouseOver={this.preloaderOnHover.bind(this, route.component)}
                      onClick={this.closeHamburger}
                      exact={route.isExact}
                      className={route.navBarClass}
                      activeClassName="is-active"
                      to={route.path}
                    >
                      {route.name}
                    </NavLink>
                    {route.secondLevel && (
                      <Fragment>
                        <SvgIcon name="select-arrow" />
                        <div className="header__menu-second">
                          <ul>
                            {route.secondLevel.map((subroute, index) => (
                              <li key={index}>
                                <NavLink onClick={this.closeHamburger} activeClassName="is-active" to={subroute.path}>
                                  {subroute.name}
                                </NavLink>
                                {subroute.children && (
                                  <Fragment>
                                    <ul>
                                      {subroute.children.map((child, index) => (
                                        <li key={index}>
                                          <NavLink
                                            onClick={this.closeHamburger}
                                            activeClassName="is-active"
                                            to={child.path}
                                          >
                                            {child.name}
                                          </NavLink>
                                        </li>
                                      ))}
                                    </ul>
                                  </Fragment>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Fragment>
                    )}
                  </li>
                ))}
                <li key={999}>
                  {hasForm ? (
                    <button className="btn" onClick={ScrollTo.bind(this, '.bottom-cta', 300)}>
                      Get started
                    </button>
                  ) : (
                    <NavLink
                      onClick={this.closeHamburger}
                      exact
                      className="btn"
                      activeClassName="is-active"
                      to={'/get-started'}
                    >
                      Get started
                    </NavLink>
                  )}
                </li>
              </ul>
              <div className="header__hamburger">
                <div
                  className={'hamburger hamburger--squeeze ' + (menuOpened ? 'is-active' : '')}
                  onClick={this.toggleHamburger}
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className={'mobile-navi ' + (menuOpened ? 'is-active' : '')}>
          <div className="container">
            <div className="mobile-navi__wrapper">
              <ul className="mobile-navi__menu">
                {routes.map((route) => (
                  <li key={route.path}>
                    <NavLink
                      onMouseOver={this.preloaderOnHover.bind(this, route.component)}
                      onClick={this.closeHamburger}
                      exact={route.isExact}
                      className={route.navBarClass}
                      activeClassName="is-active"
                      to={route.path}
                    >
                      {route.name}
                    </NavLink>
                    {route.secondLevel && (
                      <ul>
                        {route.secondLevel.map((subroute, index) => (
                          <li key={index}>
                            <NavLink onClick={this.closeHamburger} activeClassName="is-active" to={subroute.path}>
                              {subroute.name}
                            </NavLink>

                            {subroute.children && (
                              <Fragment>
                                <ul>
                                  {subroute.children.map((child, index) => (
                                    <li key={index}>
                                      <NavLink
                                        onClick={this.closeHamburger}
                                        activeClassName="is-active"
                                        to={child.path}
                                      >
                                        {child.name}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </Fragment>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuOpened: state.header.menuOpened,
  stateClass: state.header.stateClass,
});

const mapDispatchToProps = (dispatch) => ({
  openMenu: () => dispatch({ type: OPEN_MENU }),
  closeMenu: () => dispatch({ type: CLOSE_MENU }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(onClickOutside(Header)));
