export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const SET_HEADER_CLASS = 'SET_HEADER_CLASS';
export const ADD_USER = 'ADD_USER';
export const SET_FOOTER_CLASS = 'SET_FOOTER_CLASS';

export const SET_SIGNUP_STEP = 'SET_SIGNUP_STEP';
export const SET_SIGNUP_EMAIL = 'SET_SIGNUP_EMAIL';
export const SET_SIGNUP_ID = 'SET_SIGNUP_ID';
export const SET_SIGNUP_FIELDS = 'SET_SIGNUP_FIELDS';
export const LOCK_SIGNUP_ALT = 'LOCK_SIGNUP_ALT';

export const SET_PRICING_PLAN = 'SET_PRICING_PLAN';
export const ADD_PRICING_OPTION = 'ADD_PRICING_OPTION';
export const REMOVE_PRICING_OPTION = 'REMOVE_PRICING_OPTION';
export const ADD_PRICING_OPTION_SUB = 'ADD_PRICING_OPTION_SUB';
export const REMOVE_PRICING_OPTION_SUB = 'REMOVE_PRICING_OPTION_SUB';
export const REMOVE_ALL_PRICING_OPTIONS_SUB = 'REMOVE_ALL_PRICING_OPTIONS_SUB';
export const SET_PRICING_SLIDER = 'SET_PRICING_SLIDER';
export const SET_PRICING_SECTION = 'SET_PRICING_SECTION';

export const ADD_TO_DATALAYER = 'ADD_TO_DATALAYER';
export const PUSH_TO_DATALAYER = 'PUSH_TO_DATALAYER';
export const RESET_DATALAYER = 'RESET_DATALAYER';

export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const SET_ONBOARDING_FIELDS = 'SET_ONBOARDING_FIELDS';
export const SET_ONBOARDING_URLTOKEN = 'SET_ONBOARDING_URLTOKEN';
export const SET_ONBOARDING_AUTHTOKEN = 'SET_ONBOARDING_AUTHTOKEN';
export const SET_ONBOARDING_COMPANY_ID = 'SET_ONBOARDING_COMPANY_ID';
export const SET_ONBOARDING_REPRESENTATIVE_ID = 'SET_REPRESENTATIVE_ID';
export const SET_ONBOARDING_MANAGERS = 'SET_ONBOARDING_MANAGERS';
export const SET_ONBOARDING_COMPANY_NAME = 'SET_ONBOARDING_COMPANY_NAME';
export const SET_ONBOARDING_CORPORATE_SHAREHOLDER_NAME = 'SET_ONBOARDING_CORPORATE_SHAREHOLDER_NAME';
export const SET_ONBOARDING_CORPORATE_SHAREHOLDER_ID = 'SET_ONBOARDING_CORPORATE_SHAREHOLDER_ID';
export const SET_ONBOARDING_TYPE = 'SET_ONBOARDING_TYPE';
export const SET_COUNTRIES = 'SET_COUNTRIES';
